<!-- 组织架构 -->
<template>
    <div class="framework">
        <el-row>
            <el-col :span="9" :push="1">
                <div class="custom-tree-container">
                    <div class="txtbox">
                        公司岗位
                    </div>
                    <el-tree :data="data" node-key="id" :expand-on-click-node="true" :accordion="true">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span class="span-1" :class="data.children?'fontw':''">{{ node.label }}</span>
                            <span class="span-2" style="">
                                <i v-if="data.bool !==true" @click="() => addnode(node,data)" class="el-icon-edit"></i>
                                <i v-if="data.bool !==true" @click="() => deletes(node,data)"
                                    class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </el-col>
            <!-- 编辑弹窗 -->
            <el-dialog title="岗位编辑" :visible.sync="dialogFormVisible">
                <el-divider></el-divider>
                <el-form :label-position="labelPosition" ref="ruleForm" :rules="rule" class="form-1" label-width="80px"
                    :model="ruleForm">
                    <el-form-item label="岗位名称" prop="jobName">
                        <el-input v-model="ruleForm.jobName"></el-input>
                    </el-form-item>
                    <el-form-item label="上级" prop="superior">
                        <el-select v-model="ruleForm.superior" placeholder="请选择上级" :clearable="true">
                            <el-option v-for="item in ruleForm.superiorChoose" :key="item.value"
                                :label="item.position_name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="电话" prop="telephone">
                        <el-input v-model="ruleForm.telephone"></el-input>
                    </el-form-item>
                    <el-form-item label="传真">
                        <el-input v-model="ruleForm.facsimile"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="ruleForm.remarks"></el-input>
                    </el-form-item>

                </el-form>
                <el-divider></el-divider>
                <div class="footer">
                    <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="edit">
                        保存
                    </el-button>
                    <el-button style="background-color: #f5f5f5;color: #666;" @click="dialogFormVisible=false">取消
                    </el-button>
                </div>
            </el-dialog>
            <!-- 右边 -->
            <el-col :span="13" :push="1">
                <div class="c-right">
                    <div class="c-right-txt">
                        新建岗位
                    </div>
                    <div class="">
                        <el-form :label-position="labelPosition" ref="form" :rules="rules" label-width="80px"
                            :model="form">
                            <el-form-item label="岗位名称" prop="jobName">
                                <el-input v-model="form.jobName"></el-input>
                            </el-form-item>
                            <el-form-item label="上级" prop="superior">
                                <el-select v-model="form.superior" placeholder="请选择上级" :clearable="true">
                                    <el-option label="顶级" :value="0">
                                    </el-option>
                                    <el-option v-for="item in form.superiorChoose" :key="item.value"
                                        :label="item.position_name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="电话" prop="telephone">
                                <el-input v-model="form.telephone"></el-input>
                            </el-form-item>
                            <el-form-item label="传真">
                                <el-input v-model="form.facsimile"></el-input>
                            </el-form-item>
                            <el-form-item label="备注">
                                <el-input v-model="form.remarks"></el-input>
                            </el-form-item>

                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')"> 保存
                            </el-button>
                            <el-button style="background-color: #f5f5f5;color: #666;" @click="resetForm('form')">取消
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            const data = [
                // {
                //     id: 0,
                //     bool: true,
                //     label: '学院高管',
                //     children: [{
                //             id: 1,
                //             label: '校长'
                //         }, {
                //             id: 2,
                //             label: '副校长'
                //         },
                //         {
                //             id: 3,
                //             label: '校长助理'
                //         },
                //         {
                //             id: 4,
                //             label: '会计'
                //         }
                //     ]
                // }, {
                //     id: 1,
                //     bool: true,
                //     label: '学院中层',
                //     children: [{
                //             id: 1,
                //             label: '教学主任'
                //         }, {
                //             id: 2,
                //             label: '教学主管'
                //         },
                //         {
                //             id: 3,
                //             label: '考级办'
                //         },
                //         {
                //             id: 4,
                //             label: '教务专员'
                //         },
                //         {
                //             id: 5,
                //             label: '校务专员'
                //         }
                //     ]
                // }, {
                //     id: 3,
                //     bool: true,
                //     label: '教师岗位',
                //     children: [{
                //         id: 1,
                //         label: '1'
                //     }]
                // }, {
                //     id: 4,
                //     bool: true,
                //     label: '员工岗位',
                //     children: [{
                //         id: 1,
                //         label: '1'
                //     }, ]
                // }, {
                //     id: 5,
                //     bool: true,
                //     label: '临时岗位',
                //     children: [{
                //         id: 1,
                //         label: '1'
                //     }, ]
                // }
            ];
            return {
                data: JSON.parse(JSON.stringify(data)),
                labelPosition: 'right',
                form: {
                    jobName: '', //岗位名称
                    superiorChoose: [], //上级选项
                    superior: "", //上级
                    telephone: "", //电话
                    facsimile: "", //传真
                    remarks: "" //备注
                },
                rules: {
                    jobName: [{
                            required: true,
                            message: '请输入岗位名称',
                            trigger: 'blur'
                        },
                        // { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' },
                    ],
                    superior: [{
                        required: true,
                        message: '请选择上级',
                        trigger: 'change'
                    }],
                    telephone: [{
                        required: true,
                        message: '请输入电话号码',
                        trigger: 'blur'
                    }],
                },
                dialogFormVisible: false,
                ruleForm: {
                    jobName: '', //岗位名称
                    superiorChoose: [], //上级选项
                    superior: "", //上级
                    telephone: "", //电话
                    facsimile: "", //传真
                    remarks: "", //备注
                    id: "" //id
                },
                rule: {
                    jobName: [{
                        required: true,
                        message: '请输入岗位名称',
                        trigger: 'blur'
                    }, ],
                    superior: [{
                        required: true,
                        message: '请选择上级',
                        trigger: 'change'
                    }],
                    telephone: [{
                        required: true,
                        message: '请输入电话号码',
                        trigger: 'blur'
                    }],
                },
                bool: false,
                flag: false
            }
        },
        created() {
            this.init() //获取数据
            this.gain() //获取上级选项数据
        },
        activated() {
            this.init() //获取数据
        },
        methods: {
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            init() {
                console.log(this.data)
                this.$request({
                    url: "/api/org/list",
                    method: "POST",
                    data: {}
                }).then(res => {
                    console.log(res)
                    this.data = res.data.list
                })
            },
            gain() { //获取上级选项数据
                this.$request({
                    url: "/api/org/top_list",
                    method: "POST",
                    data: {}
                }).then(res => {
                    console.log(res)
                    this.form.superiorChoose = res.data.list
                    this.ruleForm.superiorChoose = res.data.list
                })
            },
            addnode(data) { //获取编辑内容详情
                this.dialogFormVisible = true
                console.log("data", data)
                this.$request({
                    url: "/api/org/detail",
                    method: "POST",
                    data: {
                        id: data.data.id
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        this.ruleForm.id = res.data.data.id //id
                        this.ruleForm.jobName = res.data.data.position_name //岗位名称
                        this.ruleForm.superior = res.data.data.pid //上级
                        this.ruleForm.telephone = res.data.data.telephone //电话
                        this.ruleForm.facsimile = res.data.data.fax //传真
                        this.ruleForm.remarks = res.data.data.remark //备注
                    }
                })

            },
            edit() { //编辑
                let obj = {
                    id: this.ruleForm.id, //组织结构id
                    pid: this.ruleForm.superior, //上级id，顶级时为0
                    telephone: this.ruleForm.telephone, //电话
                    fax: this.ruleForm.facsimile, //传真
                    remark: this.ruleForm.remarks, //说明
                    position_name: this.ruleForm.jobName //岗位名称
                }
                if (this.flag) {
                    return
                }
                this.flag = true
                this.$request({
                    url: "/api/org/edit",
                    method: "POST",
                    data: obj
                }).then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        this.$message({
                          message:"编辑成功",
                          type: 'success'
                        });
                        this.dialogFormVisible = false
                        this.init() //获取数据
                    } else {
                        this.$message({
                          message:res.msg,
                          type: 'error'
                        });
                        this.dialogFormVisible = false
                    }
                }).catch(()=>{
                    this.flag=false
                })
            },
            submitForm(form) { //添加提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if (this.bool) {
                            return
                        }
                        this.bool = true
                        setTimeout(()=>{
                                this.bool=false
                        },1500)
                        let obj = {
                            position_name: this.form.jobName, //岗位名称
                            pid: this.form.superior, //顶级组织机构id
                            telephone: this.form.telephone, //电话
                            fax: this.form.facsimile, //传真
                            remark: this.form.remarks //备注
                        }
                        this.$request({
                            url: "/api/org/add",
                            method: "POST",
                            data:obj
                        }).then(res => {
                            console.log(res)
                            if(res.code == 1){
                               this.$message({
                                 message: '新增成功',
                                 type: 'success'
                               })
                               this.form.jobName='' //岗位名称
                               this.form.superior= 0//顶级组织机构id
                               this.form.telephone='' //电话
                               this.form.facsimile= ''//传真
                               this.form.remarks='' //备注
                            this.init() //获取数据
                            }else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                              
                            }
                            
                        }).catch(()=>{
                            this.bool = false
                        })
                      
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消添加
                this.$refs[form].resetFields();
                this.form.jobName='' //岗位名称
                this.form.superior= 0//顶级组织机构id
                this.form.telephone='' //电话
                this.form.facsimile= ''//传真
                this.form.remarks='' //备注
                
            },
            deletes(node, data) {
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(d => d.id === data.id);
                // console.log(node.data.id)
                // children.splice(index, 1);
                this.$confirm('此操作将永久删除该数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    acncelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: "/api/org/del",
                        method: "POST",
                        data:{
                            id: node.data.id
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                                message: "删除成功",
                                type: 'success'
                            });
                            this.init()
                        }else{
                            this.$message({
                                message: res.msg,
                                type: 'error'
                            });
                            this.init()
                        }
                    })
                    
                    // DelFramework({
                    //     id: node.data.id
                    // }).then((res) => {
                    //     console.log(res)
                    //     this.$notify({
                    //         title: '成功',
                    //         message: '删除成功',
                    //         type: 'success'
                    //     });
                    //     this.init()
                    // })
                })
            }
        }
    }
</script>

<style scoped="scoped">
    .framework {
        width: 100%;
        height: 100%;
    }

    /* 树形菜单 */
    .shetow {
        padding: 15px 0px;
    }

    .el-tree {
        margin-top: 33px;
        margin-left: 42px;

    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333333;
        font-size: 14px;
        padding-right: 8px;
    }

    .fontw {
        font-weight: bold;
        color: #333333;
    }

    /* .framework .custom-tree-container .el-tree-node__content {
        height: 40px;
    } */
    /* 左边 */
    .custom-tree-container {
        margin-top: 40px;
        margin-right: 30px;
        height: 497px;
        border: 1px solid #E6E6E6;
    }

    .txtbox {
        height: 40px;
        line-height: 40px;
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
        color: #FFFFFF;
        background-color: #18BC9C;
    }

    /* 弹出框 */
    .dialog-footer {
        text-align: center;
        /* padding-bottom: 20px; */
    }

    ::v-deep .el-dialog__footer {
        padding: 0px !important;
    }

    ::v-deep .el-dialog {
        width: 667px !important;
        height: 485px !important;
    }

    ::v-deep.el-dialog__header {
        border: #000000 1px solid;
    }

    .el-divider--horizontal {
        margin: 0px;
    }

    ::v-deep.footer {
        margin-top: 10px !important;
        text-align: center !important;
        /* height: 50px !important; */
    }

    ::v-deep.el-dialog__footer {
        padding: 0px !important;
    }

    .form-1 {
        padding: 10px !important;
        width: 500px !important;
        margin: 0px auto !important;
        margin-top: 5px !important;
    }

    /* 子菜单选项 */
    .span-2 {
        height: 24px;
        margin: 0px 10px;
        color: #999;
    }

    .custom-tree-container .el-tree .el-tree-node__content button:first-child {
        width: 60px;
    }

    .el-icon-edit {
        padding-right: 15px;
    }

    /* 右边 */
    .c-right {
        height: 497px;
        margin-top: 40px;
        border: 1px solid #E6E6E6;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    .c-right-txt {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding-left: 30px;
        color: #FFFFFF;
        background-color: #18BC9C;
    }

    .el-form {
        width: 500px;
        padding: 0px 20px;
        margin: 0px auto;
        margin-top: 49px;
    }

    .el-select {
        width: 370px;
    }

    .el-input {
        width: 370px;
    }

    .dialog-footer {
        margin-top: 49px;
        text-align: center;
    }
</style>
